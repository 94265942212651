import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faUser, faMapMarked, faMoneyBill, faStickyNote, faInfoCircle, faCoins } from '@fortawesome/free-solid-svg-icons';
import ReCAPTCHA from 'react-google-recaptcha';
import { navigate } from 'gatsby';
import { loadStripe } from '@stripe/stripe-js';
import { url, formUrl, purchaseSuccessUrl, purchaseCancelUrl, bookSuccessUrl, bookCancelUrl } from '../../../src/hooks/use-form-url';
import base64Url from 'base64-url';
import "babel-polyfill";
import { CopyToClipboard } from 'react-copy-to-clipboard';

const RECAPTCHA_KEY = process.env.GATSBY_SITE_RECAPTCHA_KEY;
const recaptchaRef = React.createRef();
const stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);

function Form(props) {
  const [display, setDisplay] = useState("none")
  const [copied, setCopied] = useState("none")
  const [error, setError] = useState("")
  const [submitDisabled, setSubmitDisabled] = useState(false)
  const [roi, setRoi] = useState(0)
  const [encodeUrl, setEncodeUrl] = useState("")
  const [copyToClipboardDisabled, setCopyToClipboardDisabled] = useState(true)
  const [description, setDescription] = useState("")
  const [amount, setAmount] = useState(0)
  const [value, setValue] = useState("")
  const langKey = props.langKey
  var recaptchaError
  var formUrlFinal

  if (formUrl == "https://form.pradosdelsol.com/") {
    formUrlFinal = formUrl + "book"
  } 
  else if (formUrl == "https://form.madmanpierre.com/") {
    formUrlFinal = formUrl + "contact"
  } 
  else if (formUrl == "https://form.abilityoasis.com/") {
    formUrlFinal = formUrl + "ability-oasis-contact"
  } 
  else if (formUrl == "https://form.maintenanceconnection.ca/") {
    if (props.data.formType == "application") {
      formUrlFinal = formUrl + "applicationform"
    } else {
      formUrlFinal = formUrl + "contact"
    }
  }

  switch(langKey) {
    case "en":
      recaptchaError = "Please verify that you are not a robot!"
      break
    case "es":
      recaptchaError = "Por favor verifique que no es un robot!"
      break
    case "fr":
      recaptchaError = "Veuillez vérifier que vous n'êtes pas un robot!"
      break
  }

  const handleRecaptcha = async (value) => {
    if (!value || value == "") {
      setSubmitDisabled(false)
      if (value == "") {
        setError(recaptchaError)
        setDisplay("block")
        return
      }
    } else {
      if (error && error === recaptchaError) {
        setError("")
        setDisplay("none")
      }
    }

    const recaptcha = {
      token: value
    }

    await fetch('/.netlify/functions/verify-recaptcha', {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(recaptcha),
    }).then((res) => {
      return res.json();
    });
  };

  const validateRecaptcha = () => {
    const recaptchaValue = recaptchaRef.current.getValue()

    if (!recaptchaValue || recaptchaValue == "") {
      setSubmitDisabled(false)
      if (recaptchaValue == "") {
        setError(recaptchaError)
        setDisplay("block")
        return false
      }
    } else {
      if (error && error === recaptchaError) {
        setError("")
        setDisplay("none")
      }
      return true
    }
  }

  const handleDelete = () => {
    if (display === "block") {
      setDisplay("none")
    }
    if (copied === "block") {
      setCopied("none")
    }
  }

  const handleDecode = (event) => {
    event.preventDefault()
    var decodeParam = ''
    var separatorIndex = 0

    decodeParam = value
    decodeParam = decodeParam.replace(url, '').slice(1)
    decodeParam = base64Url.decode(decodeParam)

    separatorIndex = decodeParam.search("&")
    setDescription(decodeParam.substring(13, separatorIndex))
    setAmount(decodeParam.substring(separatorIndex+9))
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (submitDisabled) {
      return
    }
    setSubmitDisabled(true)

    if (props.data.formType === 'roi') {
      const data = new FormData(event.target);
      var totalSavings = 0
      data.delete('g-recaptcha-response');

      var details = {}
      for(var pair of data.entries()) {
        details[pair[0]] = pair[1]
      }

      totalSavings = await fetch('/.netlify/functions/calculate-roi', {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ details }),
      }).then((res) => {
        return res.json();
      });
      setRoi(totalSavings)
      setSubmitDisabled(false)
    } else if (props.data.formType === 'urlEncrypt') {
      const data = new FormData(event.target);
      data.delete('encodeUrl');
      var encodeParam = ''

      for(var pair of data.entries()) {
        encodeParam = encodeParam + pair[0] + '?=' + pair[1] + '&'
      }
      encodeParam = encodeParam.slice(0, -1)
      encodeParam = base64Url.encode(encodeParam)
      setEncodeUrl(url + '/' + encodeParam)
      setSubmitDisabled(false)
      setCopyToClipboardDisabled(false)
      setCopied("none")
    } else {
      if (validateRecaptcha()) {
        const data = new FormData(event.target);
        data.delete('g-recaptcha-response');

        if (props.data.formType === "contact" || props.data.formType === "application" || props.data.formType === "contact-us" ) {
          console.log(formUrlFinal)
          fetch(formUrlFinal, {
            method: 'POST',
            body: data,
            mode: 'no-cors',
          });
          navigate((langKey === "en" ? "/thanks" : "/" + langKey + "/thanks"))
        } else if (props.data.formType === "purchase" || props.data.formType === "book" || props.data.formType === "urlDecrypt" ) {
          var details
  
          if (props.data.formType === "purchase" || props.data.formType === "urlDecrypt") {
            details = {
              type: "purchase",
              name: data.get("description"),
              amount: Math.round(data.get("amount") * 100),
              currency: data.get("currency"),
              quantity: data.get("quantity"),
              successUrl: (langKey === "en" ? url + purchaseSuccessUrl : url + "/" + langKey + purchaseSuccessUrl),
              cancelUrl: (langKey === "en" ? url + purchaseCancelUrl : url + "/" + langKey + purchaseCancelUrl),
              submitType: "pay",
              langKey: langKey,
            }
          } else if (props.data.formType === "book") {
            details = {
              type: "room",
              code: data.get("room"),
              couponCode: data.get("coupon"),
              quantity: 1,
              successUrl: (langKey === "en" ? url + bookSuccessUrl : url + "/" + langKey + bookSuccessUrl),
              cancelUrl: (langKey === "en" ? url + bookCancelUrl : url + "/" + langKey + bookCancelUrl),
              submitType: "book",
              langKey: langKey,
            }
          }
  
          const checkoutResponse = await fetch('/.netlify/functions/create-checkout-session', {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ details }),
          }).then((res) => {
            return res.json();
          });
  
          if (checkoutResponse.error) {
            setError(checkoutResponse.error)
            setDisplay("block")
            setSubmitDisabled(false)
            return
          } else {
            setError("")
            setDisplay("none")
            setSubmitDisabled(true)

            fetch(formUrlFinal, {
              method: 'POST',
              body: data,
              mode: 'no-cors',
            });
  
            const sessionId = checkoutResponse.session.id
            const stripe = await stripePromise
            const { error } = await stripe.redirectToCheckout({
              sessionId,
            })
  
            if (error) {
              console.warn("Error:", error)
            }
          }
        }
      }
    }
  }

  return (
    <div className="content" style={{textAlign: 'center'}}>
      {props.data.title != null
        ? <p
            className='has-text-weight-bold'
            style={{
              fontSize: '25px',
            }}>
            {props.data.title}
          </p>
        : null
      }
      <div className="box">
        <>
          <form name="contact" onSubmit={handleSubmit} data-netlify-recaptcha="true" className="form">
            {props.data.fieldGroups != null ? (
              <div>
                {props.data.fieldGroups.map(fieldGroup => (
                  <>
                    <div
                      className={`${fieldGroup.groupTitleSize} has-text-weight-bold`}
                      style={{textAlign: `${fieldGroup.groupTitleAlign}`}}
                    >
                      {fieldGroup.groupTitle}
                    </div>
                    {fieldGroup.fieldRows.map(fieldRow => (
                      <div className="field is-horizontal">
                        <div className="field-body">
                          {fieldRow.fields.map(field => (
                            <>
                              {field.type === "radio" && field.labelPosition === "left" &&
                                <label
                                  className="label"
                                  style={{textAlign: 'left'}}
                                >
                                  {field.isRequiredFormat || (field.isRequired && field.isRequiredFormat == null) ? `${field.label}*` : field.label}
                                </label>
                              }
                              <div className="field">
                                <div className={`control ${field.hasIcon} is-expanded ${field.type === 'date' ? "" : "has-floating-label"}`}>
                                  {field.type === "multiLineText" ? (
                                    <>
                                      <textarea
                                        className="textarea is-small"
                                        style={{
                                          border: field.isRequiredFormat || (field.isRequired && field.isRequiredFormat == null) ? '2px solid' : null
                                        }}
                                        placeholder=" "
                                        name={field.name}
                                        aria-label={field.name}
                                        required={field.isRequired}
                                        id={field.name}
                                      />
                                      <label className="label is-floating-label" htmlFor={field.name}>{field.isRequiredFormat || (field.isRequired && field.isRequiredFormat == null) ? `${field.placeholder}*` : field.placeholder}</label>
                                    </>
                                  ) : null}
                                  {field.type === "checkbox" ? (
                                    <label className="checkbox">
                                      <input
                                        type={field.type}
                                        style={{marginRight: '0.5rem'}}
                                        name={field.name}
                                        aria-label={field.name}
                                        required={field.isRequired}
                                      />
                                      {field.isRequiredFormat || (field.isRequired && field.isRequiredFormat == null) ? `${field.label}*` : field.label}
                                    </label>
                                  ) : null}
                                  {field.type === "date" ? (
                                    <>
                                      <label
                                        className="label"
                                        style={{textAlign: 'center'}}
                                      >
                                        {field.isRequiredFormat || (field.isRequired && field.isRequiredFormat == null) ? `${field.label}*` : field.label}
                                      </label>
                                      <input
                                        className="input is-small is-date"
                                        style={{
                                          border: field.isRequiredFormat || (field.isRequired && field.isRequiredFormat == null) ? '2px solid' : null
                                        }}
                                        type={field.type}
                                        name={field.name}
                                        role="date"
                                        aria-label={field.name}
                                        required={field.isRequired}
                                      />
                                    </>
                                  ) : null}
                                  {field.type === "select" ? (
                                    <>
                                      {field.label != null ? (
                                        <label
                                          className="label"
                                          style={{textAlign: 'center'}}
                                        >
                                          {field.isRequiredFormat || (field.isRequired && field.isRequiredFormat == null) ? `${field.label}*` : field.label}
                                        </label>
                                      ) : null}
                                      <div className="select is-fullwidth is-small">
                                        <select
                                          style={{
                                            border: field.isRequiredFormat || (field.isRequired && field.isRequiredFormat == null) ? '2px solid' : null
                                          }}
                                          name={field.name}
                                          aria-label={field.name}
                                          required={field.isRequired}
                                        >
                                          {field.options.map(option => (
                                            <option value={option.code}>
                                              {option.label}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </>
                                  ) : null}
                                  {field.type === "radio" ? (
                                    <>
                                      {field.labelPosition === "top" &&
                                        <label
                                          className="label"
                                          style={{textAlign: 'center'}}
                                        >
                                          {field.isRequiredFormat || (field.isRequired && field.isRequiredFormat == null) ? `${field.label}*` : field.label}
                                        </label>
                                      }
                                      <div
                                        className="control"
                                        style={{textAlign: 'center'}}
                                      >
                                        {field.options.map(option => (
                                          <label
                                            className="radio"
                                            style={{marginRight: '1rem'}}
                                          >
                                            <input
                                              type="radio"
                                              value={option.code}
                                              name={field.name}
                                              style={{marginRight: '0.5rem'}}
                                              required={field.isRequired}
                                            />
                                            {option.label}
                                          </label>
                                        ))}
                                      </div>
                                    </>
                                  ) : null}
                                  {field.type === "html" ? (
                                    <div dangerouslySetInnerHTML={{__html: `${field.value}`}} />
                                  ) : null}
                                  { (   field.type !== "checkbox"
                                     && field.type !== "multiLineText"
                                     && field.type !== "date"
                                     && field.type !== "select"
                                     && field.type !== "radio"
                                     && field.type !== "html"
                                    ) ? (
                                    <>
                                      <input
                                        className="input is-small"
                                        style={{
                                          border: field.isRequiredFormat || (field.isRequired && field.isRequiredFormat == null) ? '2px solid' : null
                                        }}
                                        type={field.type}
                                        placeholder=" "
                                        name={field.name}
                                        aria-label={field.name}
                                        required={field.isRequired}
                                        id={field.name}
                                        step={field.type === "number" ? "0.01" : false}
                                        readonly={field.isReadOnly}
                                        disabled={field.isDisabled}
                                        onChange={
                                          event => field.name === "decodeUrl" ? setValue(event.target.value) : null
                                        }
                                        value={
                                          (() => {
                                            switch (field.name) {
                                              case "totalSavings": return `${roi}`;
                                              case "encodeUrl":    return `${encodeUrl}`;
                                              case "description":  if (props.data.formType === "urlDecrypt") {
                                                                     return `${description}`
                                                                   } else {
                                                                     return null
                                                                   };
                                              case "amount":       if (props.data.formType === "urlDecrypt") {
                                                                     return `${amount}`
                                                                   } else {
                                                                     return null
                                                                   };
                                              default:             return null;
                                            }
                                          })()
                                        }
                                      />
                                      <label className="label is-floating-label" htmlFor={field.name}>{field.isRequiredFormat || (field.isRequired && field.isRequiredFormat == null) ? `${field.placeholder}*` : field.placeholder}</label>
                                    </>
                                  ) : null}
                                  {field.icon != null ? (
                                    <span className="icon is-small is-left">
                                      <div>
                                        {field.icon === "faUser" ? (
                                          <FontAwesomeIcon icon={faUser} />
                                        ) : null}
                                        {field.icon === "faEnvelope" ? (
                                          <FontAwesomeIcon icon={faEnvelope} />
                                        ) : null}
                                        {field.icon === "faPhone" ? (
                                          <FontAwesomeIcon icon={faPhone} />
                                        ) : null}
                                        {field.icon === "faMapMarked" ? (
                                          <FontAwesomeIcon icon={faMapMarked} />
                                        ) : null}
                                        {field.icon === "faMoneyBill" ? (
                                          <FontAwesomeIcon icon={faMoneyBill} />
                                        ) : null}
                                        {field.icon === "faStickyNote" ? (
                                          <FontAwesomeIcon icon={faStickyNote} />
                                        ) : null}
                                        {field.icon === "faInfoCircle" ? (
                                          <FontAwesomeIcon icon={faInfoCircle} />
                                        ) : null}
                                        {field.icon === "faCoins" ? (
                                          <FontAwesomeIcon icon={faCoins} />
                                        ) : null}
                                      </div>
                                    </span>
                                  ) : null}
                                </div>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    ))}
                  </>
                ))}
              </div>
            ) : null}
            {props.data.hasRecaptcha ? (
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={RECAPTCHA_KEY}
                onChange={handleRecaptcha}
                style={{paddingTop: "0.75rem"}}
                hl={langKey}
              />
            ) : null}
            <div className="field" style={{paddingTop: "0.75rem", textAlign: "left"}}>
              <div className="control">
                <div className="buttons">
                  <button
                    type="submit"
                    className="button is-dark is-small"
                    disabled={submitDisabled}
                  >
                    {submitDisabled ? 'Loading...' : props.data.submitText}
                  </button>
                  {props.data.hasCopyToClipboard ?
                    <CopyToClipboard
                      text={encodeUrl}
                      onCopy={() => setCopied("block")}
                    >
                      <button
                        type="button"
                        className="button is-dark is-small"
                        disabled={copyToClipboardDisabled}
                      >
                        Copy to clipboard
                      </button>
                    </CopyToClipboard>
                    : null
                  }
                  {props.data.hasDecode ?
                    <button
                      type="button"
                      className="button is-dark is-small"
                      onClick={handleDecode}
                    >
                      Decode
                    </button>
                    : null
                  }
                </div>
              </div>
            </div>
          </form>
          {props.data.hasCopyToClipboard ?
            <div style={{paddingTop: "0.75rem", display: `${copied}`}}>
              <div className="notification is-success">
                <button className="delete" onClick={handleDelete}></button>
                Copied
              </div>
            </div>
           : null
          }
          <div style={{paddingTop: "0.75rem", display: `${display}`}}>
            <div className="notification is-danger">
              <button className="delete" onClick={handleDelete}></button>
              {error}
            </div>
          </div>
        </>
      </div>
    </div>
  )
};

export default Form;