import React from 'react';
import { graphql } from 'gatsby';
import  SpecialLayoutPage  from '../components/layouts/SpecialLayoutPage';

const LayoutPage = ({data}) => {
  return (
    <SpecialLayoutPage
      data={data}
      paddingBottom="2rem"
    />
  )
}
export default LayoutPage

export const pageQuery = graphql`
  query LayoutPageTemplateByID($id: String!, $langKey: String!) {
    page: markdownRemark(
      fields: { langKey: { eq: $langKey } }
      frontmatter: { templateKey: { eq: "special-layout-page" } }, id: { eq: $id }
    ) {
      fields {
        langKey
        slug
        pathSlug
      }
      frontmatter {
        title
        contents {
          isFullWidth
          columnCount
          columns {
            columnOne {
              chunk {
                align
                type
                value
                image {
                  childImageSharp {
                    fluid(maxWidth:2048, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                width
                height
                alt
                useMap {
                  mapName
                  area {
                    shape
                    coords
                    alt
                    link
                  }
                }
                title
                header {
                  cell
                }
                rows {
                  row {
                    cell
                    color
                    textColor
                  }
                }
                note
                entries {
                  title
                  content
                }
                link
                fieldGroups {
                  groupTitle
                  groupTitleSize
                  groupTitleAlign
                  fieldRows {
                    fields {
                      type
                      name
                      label
                      labelPosition
                      placeholder
                      hasIcon
                      icon
                      options {
                        code
                        label
                      }
                      isRequired
                      isRequiredFormat
                      isReadOnly
                      isDisabled
                      value
                    }
                  }
                }
                formType
                submitText
                hasRecaptcha
                hasCopyToClipboard
                hasDecode
                text
                button {
                  align
                  link
                  value
                }
                size
                paddingBottom
                overlay {
                  backgroundColor
                  opacity
                }
                images {
                  image {
                    childImageSharp {
                      fluid(maxWidth:2048, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  title
                  text
                }
                titles {
                  sizeMobile
                  sizeTablet
                  sizeWidescreen
                  value
                }
                isHome
                isTags
                defLatitude
                defLongitude
                defZoom
                latitude
                longitude
                label
              }
            }
            columnTwo {
              chunk {
                align
                type
                value
                image {
                  childImageSharp {
                    fluid(maxWidth:2048, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                width
                height
                alt
                useMap {
                  mapName
                  area {
                    shape
                    coords
                    alt
                    link
                  }
                }
                title
                header {
                  cell
                }
                rows {
                  row {
                    cell
                    color
                    textColor
                  }
                }
                note
                entries {
                  title
                  content
                }
                link
                fieldGroups {
                  groupTitle
                  groupTitleSize
                  groupTitleAlign
                  fieldRows {
                    fields {
                      type
                      name
                      label
                      labelPosition
                      placeholder
                      hasIcon
                      icon
                      options {
                        code
                        label
                      }
                      isRequired
                      isRequiredFormat
                      isReadOnly
                      isDisabled
                      value
                    }
                  }
                }
                formType
                submitText
                hasRecaptcha
                hasCopyToClipboard
                hasDecode
                text
                button {
                  align
                  link
                  value
                }
                size
                paddingBottom
                overlay {
                  backgroundColor
                  opacity
                }
                images {
                  image {
                    childImageSharp {
                      fluid(maxWidth:2048, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  title
                  text
                }
                titles {
                  sizeMobile
                  sizeTablet
                  sizeWidescreen
                  value
                }
                isHome
                isTags
                defLatitude
                defLongitude
                defZoom
                latitude
                longitude
                label
              }
            }
            columnThree {
              chunk {
                align
                type
                value
                image {
                  childImageSharp {
                    fluid(maxWidth:2048, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                width
                height
                alt
                useMap {
                  mapName
                  area {
                    shape
                    coords
                    alt
                    link
                  }
                }
                title
                header {
                  cell
                }
                rows {
                  row {
                    cell
                    color
                    textColor
                  }
                }
                note
                entries {
                  title
                  content
                }
                link
                fieldGroups {
                  groupTitle
                  groupTitleSize
                  groupTitleAlign
                  fieldRows {
                    fields {
                      type
                      name
                      label
                      labelPosition
                      placeholder
                      hasIcon
                      icon
                      options {
                        code
                        label
                      }
                      isRequired
                      isRequiredFormat
                      isReadOnly
                      isDisabled
                      value
                    }
                  }
                }
                formType
                submitText
                hasRecaptcha
                hasCopyToClipboard
                hasDecode
                text
                button {
                  align
                  link
                  value
                }
                size
                paddingBottom
                overlay {
                  backgroundColor
                  opacity
                }
                images {
                  image {
                    childImageSharp {
                      fluid(maxWidth:2048, quality: 100) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                  title
                  text
                }
                titles {
                  sizeMobile
                  sizeTablet
                  sizeWidescreen
                  value
                }
                isHome
                isTags
                defLatitude
                defLongitude
                defZoom
                latitude
                longitude
                label
              }
            }
          }
        }
      }
    }
    reusable: allMdx(
      filter: {frontmatter: { key: { eq: "reusable" }, langKey: { eq: $langKey } }}
    ) {
      edges {
        node {
          body
          frontmatter {
            title
          }
        }
      }
    }
  }
`
